"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageType = void 0;
var MessageType;
(function (MessageType) {
    MessageType["Resize"] = "resize";
    MessageType["Hash"] = "hash";
    MessageType["GetParam"] = "get_param";
    MessageType["ScrollToTopIfNeeded"] = "scroll_to_top_if_needed";
})(MessageType = exports.MessageType || (exports.MessageType = {}));
