"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Resizer = void 0;
var iframe_1 = require("./iframe");
var Resizer = /** @class */ (function () {
    function Resizer() {
    }
    Resizer.setHeight = function (height, iframeSrc) {
        // The "server side" script sends a hostname if the site is a widget
        // If we have one or more iframes on the client site with the same src set, we resize them.
        // Otherwise we default to the first iframe
        if (iframeSrc) {
            for (var _i = 0, _a = iframe_1.Iframe.getIframesWithSrc(iframeSrc); _i < _a.length; _i++) {
                var iframe_2 = _a[_i];
                iframe_2.style.height = height + 'px';
            }
            return;
        }
        var iframe = iframe_1.Iframe.getFirstIframe();
        if (!iframe) {
            return;
        }
        iframe.style.height = height + 'px';
    };
    return Resizer;
}());
exports.Resizer = Resizer;
