"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyleTag = void 0;
var iframe_1 = require("./iframe");
var StyleTag = /** @class */ (function () {
    function StyleTag() {
    }
    StyleTag.add = function () {
        var css = "iframe".concat(iframe_1.Iframe.iframeSelector, " { width: 100%; border: 0; }");
        var head = document.head || document.getElementsByTagName('head')[0];
        var style = document.createElement('style');
        style.setAttribute('type', 'text/css');
        style.appendChild(document.createTextNode(css));
        head.appendChild(style);
    };
    return StyleTag;
}());
exports.StyleTag = StyleTag;
