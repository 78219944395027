"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetParam = void 0;
var GetParam = /** @class */ (function () {
    function GetParam() {
    }
    Object.defineProperty(GetParam, "all", {
        /**
         * Returns all GET parameters in the browser in an array (eg. ["mw=all", "page=2", "utm_source=facebook"])
         */
        get: function () {
            return location.search.substr(1).split('&');
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Append GET parameters from the browser to our own parameter string
     * @param base (eg. "?mw=c_2_coupon-with-a-lot-of-discount")
     */
    GetParam.appendClientParams = function (base) {
        var path = base;
        // Check if we have something else than ?mw= or ?page= in the browsers get parameters
        var otherParams = GetParam.all.filter(function (param) { return param.indexOf('mw=') !== 0 && param.indexOf('page=') !== 0 && param.length !== 0; });
        if (otherParams.length > 0) {
            path += '&' + otherParams.join('&');
        }
        return path;
    };
    return GetParam;
}());
exports.GetParam = GetParam;
