"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Iframe = void 0;
var get_param_1 = require("./get-param");
var Iframe = /** @class */ (function () {
    function Iframe() {
    }
    Iframe.getFirstIframe = function () {
        return document.querySelector(this.iframeSelector);
    };
    Iframe.getIframesWithSrc = function (src) {
        // Select all matching iframes as a NodeList
        var iframes = document.querySelectorAll(this.iframeSelector);
        // We can't use the Array.from() to convert the NodeList to an array (not supported in IE11), so therefore we convert it using the slice function
        // https://stackoverflow.com/a/7459729
        return Array.prototype.slice.call(iframes, 0).filter(function (el) { return el.getAttribute('src') === src; });
    };
    Iframe.updateSrc = function () {
        // Don't update the iframe src if we don't have a path
        if (!this.getPathFromSearch() && !this.getPathFromHash()) {
            return;
        }
        var iframe = this.getFirstIframe();
        if (!iframe) {
            return;
        }
        var src = iframe.getAttribute('src');
        if (!src) {
            return;
        }
        // We don't want to change the src for iframes with widgets
        if (src.indexOf('/widgets/') > 0) {
            return;
        }
        // Find the path from the search
        var path = this.getPathFromSearch();
        if (!path) {
            // If the path couldn't be found in the search, we use the hash
            path = this.getPathFromHash();
        }
        var base = src.split('/').slice(0, 6).join('/');
        iframe.setAttribute('src', "".concat(base, "/").concat(path));
    };
    Iframe.getPathFromSearch = function () {
        var parameters = get_param_1.GetParam.all;
        var mw = parameters.filter(function (param) { return param.indexOf('mw=') === 0; });
        // Stop if we don't have a mw parameter
        if (mw.length === 0) {
            return null;
        }
        var path = mw[0]
            .substr(3) // Remove "mw="
            .replace(new RegExp('_', 'g'), '/');
        var page = parameters.filter(function (param) { return param.indexOf('page=') === 0; });
        if (page.length > 0) {
            path += '?' + page[0];
        }
        return path;
    };
    Iframe.getPathFromHash = function () {
        // Stop if we don't have more than the # itself
        if (location.hash.length <= 1) {
            return null;
        }
        return location.hash.substr(1);
    };
    Iframe.iframeSelector = '.emplate-mall-web-shell';
    return Iframe;
}());
exports.Iframe = Iframe;
