"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var iframe_1 = require("./iframe");
var style_tag_1 = require("./style-tag");
var message_listener_1 = require("./message-listener");
// Listen for messages from the iframe content
message_listener_1.MessageListener.boot();
// Wait for the document to be ready
document.onreadystatechange = function () {
    if (document.readyState === 'interactive') {
        iframe_1.Iframe.updateSrc();
        style_tag_1.StyleTag.add();
    }
};
