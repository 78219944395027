"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Scroller = void 0;
var iframe_1 = require("./iframe");
var Scroller = /** @class */ (function () {
    function Scroller() {
    }
    Scroller.toTopIfNeeded = function (offset) {
        var iframe = iframe_1.Iframe.getFirstIframe();
        if (!iframe) {
            return;
        }
        // Scroll to top if the top of iframe is out of viewport
        var iframeTop = iframe.getBoundingClientRect().top;
        var scrollTopOffset = offset;
        if (iframeTop < -scrollTopOffset) {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            window.scrollTo(0, iframeTop + scrollTop - scrollTopOffset);
        }
    };
    return Scroller;
}());
exports.Scroller = Scroller;
