"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageListener = void 0;
var message_type_1 = require("../bridge/message-type");
var resizer_1 = require("./resizer");
var get_param_1 = require("./get-param");
var scroller_1 = require("./scroller");
var MessageListener = /** @class */ (function () {
    function MessageListener() {
    }
    MessageListener.boot = function () {
        window.addEventListener('message', this.receiveMessage, false);
    };
    MessageListener.receiveMessage = function (event) {
        var data = event.data;
        switch (data.type) {
            case message_type_1.MessageType.Resize:
                resizer_1.Resizer.setHeight(data.height, data.host);
                break;
            case message_type_1.MessageType.Hash:
                // When navigating in the iframe a new history entry is made in the browser, but the current location is not
                // changing as the navigation was made in the iframe. We want to replace the new entry and include the new
                // hash in it, instead of creating a new one by changing location.hash
                history.replaceState({}, '', data.path);
                break;
            case message_type_1.MessageType.GetParam:
                history.replaceState({}, '', get_param_1.GetParam.appendClientParams(data.path));
                break;
            case message_type_1.MessageType.ScrollToTopIfNeeded:
                scroller_1.Scroller.toTopIfNeeded(data.offset);
                break;
        }
    };
    return MessageListener;
}());
exports.MessageListener = MessageListener;
